* {
    margin: 0px;
    padding: 0px;
  }
  
  html,
  body {
    width: 100%;
    height: 100%;
    font-family: "Montserrat", sans-serif;
    font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
  }
  
  .strong {
    font-weight: bold;
  }
  
  #loader,
  #sceneIframe {
    position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
  }
  
  #loader {
    z-index: 1;
  }
  
  #sceneIframe {
    border: 0px;
  }
  
  #sceneIframe body {
    z-index: 0;
    margin: 0px;
    padding: 0px;
  }
  
  #loader {
    background-image: linear-gradient(to bottom, #161926, #0d0f1e);
    flex-direction: column;
  }
  
  #loadingProgress {
    position: relative;
    text-align: center;
    height: 10px;
    width: 600px;
    align-self: center;
    margin-bottom: 50px;
  }
  
  @media (max-width: 900px) {
    #loadingProgress {
      width: 300px;
    }
  }
  
  #back {
    background: #fff;
    opacity: 0.07;
    position: absolute;
    height: 100%;
    width: 100%;
  }
  
  #progressStatus {
    position: absolute;
    height: 100%;
    width: 0%;
    background: #3ad887;
    transition: 1s width ease;
  }
  
  #loader #loadingText {
    opacity: 0.8;
    text-align: center;
    color: #ffffff;
    margin-bottom: 8px;
  }
  
  #loader #animationWrapper {
    padding-top: 10vh;
    display: flex;
    justify-content: center;
    height: 50%;
  }
  
  #loader #animationWrapper svg {
    height: 30vh !important;
  }
  
  .modal {
    /* display: none; */
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  
  #qrContainer {
    display: none;
    z-index: 9999;
  }
  
  .qr-modal {
    opacity: 0.95;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 20px 0 rgba(0, 0, 0, 0.2);
    background-image: linear-gradient(to bottom, #161926, #0d0f1e);
    padding: 40px;
    color: #fff;
    font-size: 20px;
    border-radius: 5px;
  }
  
  .qr-modal-header .top-text {
    text-align: center;
    font-weight: bold;
    letter-spacing: 0.2px;
  }
  
  .qr-modal-header .sub-text {
    text-align: center;
    font-weight: 300;
    letter-spacing: 0.2px;
  }
  .qr-modal-content {
    text-align: center;
    margin-top: 40px;
  }
  #qrCode {
    height: 250px !important;
    width: 250px !important;
  }
  .modal.is-open {
    /* display: block; */
  }
  
  /* #qrContainer {
    z-index: 2;
  } */
  
  .enable-sensor-safari {
    height: 100%;
    display: none;
    flex-direction: column;
    padding-top: 5vh;
    padding-left: 5vw;
    color:black
  }
  
  .enable-sensor-safari .title {
    font-weight: bold;
    font-size: 30px;
  }
  
  .enable-sensor-safari .steps {
    display: flex;
    flex-direction: column;
    margin-top: 7vh;
    font-size: 25px;
  }
  
  .enable-sensor-safari .steps .step {
    display: flex;
    margin-top: 20px;
  }
  
  .enable-sensor-safari .steps .step .number {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgb(218, 209, 227);
    text-align: center;
    font-size: 10px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }
  
  .enable-sensor-safari .steps .step .text {
    margin-left: 20px;
    width: calc(100% - 40px);
  }
  
  .place-holder-image {
    width: 20px;
    margin-left: 3px;
    margin-right: 7px;
  }
  
  .highlight {
    color: rgb(117, 32, 180);
    font-weight: bold;
  }
  
  .ios13-gyroscope-access {
    padding: 15px;
    border-color: transparent;
    position: fixed;
    width: 100%;
    height: 100vH;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 9999;
    color: #fff;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    display: none;
    background: rgba(0,0,0,0.6);
  
      /* display: block; */
  }
  
  .ios13-gyroscope-access p {
    margin-bottom: 30px;
    line-height: 1.6;
  }
  
  .ios13-gyroscope-access div {
    display: flex;
    justify-content: space-evenly;
  }
  
  .ios13-gyroscope-access button {
    padding: 10px;
    width: 120px;
    border: 1px solid #999;
    background: #fff;
    outline: none;
    box-shadow: none;
    border-radius: 3px;
    font-family: 'Montserrat', sans-serif;
  }
  
  #cancel_gyroscope {
    opacity: 0.8;
  }
  
  #allow_gyroscope {
    background-color: rgb(68, 125, 254);
    color: #fff;
    border-color: rgb(68, 125, 254);
  }