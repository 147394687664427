body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}

html {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Liberation Sans", sans-serif;
  height: 100%;
}

header {
  margin-bottom: 40px;
  padding: 30px 20px;
  background: linear-gradient(to left, #f0f0f0, #ffffff 50%);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  text-align: right;
}

header h1 {
  margin: 0;
  color: #3d3d3d;
}

#content {
  width: 100%;
  /* max-width: 1000px;
    margin: 0 auto; */
  line-height: 1.5em;
  font-size: 1.1em;
  height: 100%;
}

#container,
#your-pano {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

/* .loader {
    margin: 0 auto;
    width: 30px;
    height: 30px;
    
    border: 3px solid transparent;
    border-top-color: #0096FF;
    border-radius: 50%;
    
    animation: spin 0.8s ease infinite;
} */

p {
  margin-bottom: 30px;
}

a {
  text-decoration: none;
  color: #0096ff;
}

a:hover {
  color: #ff9600;
}

code {
  padding: 5px 10px;
  border-radius: 3px;
  background-color: #f0f0f0;
}

.psv-loader-container {
  display: none;
}

.swipe-message {
  display: none;
  position: absolute;
  z-index: 90;
  bottom: 10%;
  left: 0;
  width: 100%;
  background: rgba(61, 61, 61, 0.5);
  width: fit-content;
  padding: 16px 25px;
  margin: 0 auto;
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.7);
}

.swipe-message > img {
  width: 30px;
  /* display: inline-block; */
  float: left;
  margin-right: 12px;
}

#swipe-message-text {
  font-size: 0.9rem;
  line-height: 20px;
}
